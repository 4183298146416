import { createApp } from 'vue';
import { createManager } from '@vue-youtube/core';
import { createHead } from '@vueuse/head'
import VueLazyLoad from 'vue3-lazyload';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import CountryFlag from 'vue-country-flag-next';
import { YoutubeIframe } from '@vue-youtube/component';
import {FaArrowCircleUp, FaArrowLeft, FaCamera, FaYoutube} from "oh-vue-icons/icons/fa";
import { addIcons, OhVueIcon } from 'oh-vue-icons';
import * as bootstrap from 'bootstrap';

import router from '../router';
import * as webcamsJson from '../config/webcam.json';
import App from '../App.vue'
import '../scss/style.scss'

const app = createApp(App);
const head = createHead();
addIcons(FaArrowCircleUp, FaCamera, FaYoutube, FaArrowLeft);

app.config.globalProperties.$webcams = {
    russia: webcamsJson.default.russia,
    northEurope: webcamsJson.default.northEurope,
    centralEurope: webcamsJson.default.centralEurope,
    southEurope: webcamsJson.default.southEurope,
    northAmerica: webcamsJson.default.northAmerica,
    southAmerica: webcamsJson.default.southAmerica,
    norway: webcamsJson.default.norway,
    sweden: webcamsJson.default.sweden,
    finland: webcamsJson.default.finland,
    asiaOceania: webcamsJson.default.asiaOceania,
    southAfrica: webcamsJson.default.southAfrica
}

app
    .component("v-icon", OhVueIcon)
    .use(createManager())
    .component('youtube-iframe', YoutubeIframe)
    .use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyBYBzFohnj91jS6okoYQByY51mMCpw9g_U',
        }
    })
    .use(CountryFlag)
    .use(head)
    .use(router)
    .use(VueLazyLoad)
    .mount('#app');
