<template>
    <AuroraWebcams />
</template>
  
  <script>
  import AuroraWebcams from '../components/aurora-webcams.vue';
  
  export default {
    name: 'AuroraWebcamHome',
    components: { 
      AuroraWebcams
    },
    data() {
      return {
      }
    },
  }
  </script>