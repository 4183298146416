<template>
    <div :id="this.cam.alias" class="card col-12" :class="this.cam.isPano ? 'col-md-12' : 'col-md-6'" >
        <a :href="'/webcam/'+this.cam.region+'/'+this.cam.alias">
            <iframe class="external-video" v-if="this.cam.isVideo && this.cam.isExternalVideo" :src="this.cam.url" frameborder="0"></iframe>
            <youtube-iframe v-else-if="this.cam.isVideo" :video-id="this.cam.url" width="1280" height="720" frameborder="0" :playerVars="{autoplay: 1, mute: 1}" />
            <img v-else class="card-img-top" width="auto" height="280" :alt="this.cam.name" v-lazy="{ src: this.cam.url+this.cacheBreaker, loading: this.loadingImgUrl, error: this.offlineImgUrl }" />
        </a>
        <div class="card-body">
            <div class="cam-name">
                <a :href="'/webcam/'+this.cam.region+'/'+this.cam.alias">
                    <country-flag :country="this.cam.country" size='normal'/>
                    <h5>{{ this.cam.name }}</h5> 
                </a>
                <span v-if="this.cam.isVideo" class="live-cam"> <v-icon name="fa-youtube" class="icon" scale="1.5"/>live cam</span>
            </div>
            <div class="cam-location">
                Location: <a :href="'https://www.google.com/maps/search/?api=1&query='+ this.cam.coord.lat +','+this.cam.coord.lng" target="_blank">{{ this.cam.coord.lat.toFixed(2) }}°N {{ this.cam.coord.lng.toFixed(2) }}°E</a>
            </div>
            <div class="cam-source">
                Source: <a :href="this.cam.source.url" target="_blank">{{ this.cam.source.label }}</a>
            </div>
        </div>
    </div>
</template>
  
<script>

import loadingImg from '../assets/loading.avif';
import offlineImg from '../assets/offline.avif';

export default {
    name: 'WebcamCard',
    props: [
        'cam',
        'cacheBreaker'
    ],
    data() {
        return {
            loadingImgUrl: loadingImg,
            offlineImgUrl: offlineImg
        }
    }
}
</script>