<template>
    <div class="container">
        <h4 class="mb-0">Webcams</h4>
        <sub>All cameras are sorted by latitude from North to South</sub>
        <a class="map-link d-block" :href="'/webcams-map'">go to webcam maps</a>
        
        <swiper class="nav nav-pills mt-3" :slides-per-view="'auto'" >
            <swiper-slide class="nav-item" v-for="(tab, index) in tabsConfig">
                <span class="nav-link" :class="this.activeCameraTab === index ? 'active' : ''" @click="this.activeCameraTab = index">{{ tab.label }}</span>
            </swiper-slide>
        </swiper>

        <div class="row accordion" id="accordionCam">
            <div class="accordion-item">
                <h2 class="accordion-header" id="accordionCamHeader">
                <button class="accordion-button collapsed" :disabled="this.activeCameraTab === null" type="button" data-bs-toggle="collapse" data-bs-target="#accordionCamList" aria-expanded="true" aria-controls="accordionCamList">
                    Show webcam list
                </button>
                </h2>
                <div id="accordionCamList" class="accordion-collapse collapse" aria-labelledby="accordionCamHeader" data-bs-parent="#accordionCam" v-if="this.activeCameraTab !== null">
                    <div class="container mt-3 mb-5 p-0">
                        <ul class="row cam-list">
                            <li class="col-12 col-sm-6 col-lg-4 col-xl-3 cam-link" style="text-align: left;" v-for="cam in this.orderCamsByLat(this.$webcams[tabsConfig[this.activeCameraTab].id], true)">
                                <v-icon name="fa-camera" class="icon" scale="1"/> 
                                <country-flag :country="cam.country" size='small' class="flag-list"/> 
                                <a :href="cam.onlyList ? cam.url : '#'+cam.alias" :target="cam.onlyList ? '_blank' : '_self'">
                                    {{ cam.name }}
                                    <svg v-if="cam.onlyList" xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                        <mask id="mask0_612_6095" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="21">
                                        <rect y="0.5" width="20" height="20" fill="#D9D9D9"/>
                                        </mask>
                                        <g mask="url(#mask0_612_6095)">
                                        <path d="M4.42321 17.5834C4.00223 17.5834 3.64591 17.4376 3.35425 17.1459C3.06258 16.8542 2.91675 16.4979 2.91675 16.077V4.92321C2.91675 4.50223 3.06258 4.14591 3.35425 3.85425C3.64591 3.56258 4.00223 3.41675 4.42321 3.41675H9.67946V4.66675H4.42321C4.35904 4.66675 4.30029 4.69348 4.24696 4.74696C4.19348 4.80029 4.16675 4.85904 4.16675 4.92321V16.077C4.16675 16.1411 4.19348 16.1999 4.24696 16.2532C4.30029 16.3067 4.35904 16.3334 4.42321 16.3334H15.577C15.6411 16.3334 15.6999 16.3067 15.7532 16.2532C15.8067 16.1999 15.8334 16.1411 15.8334 16.077V10.8207H17.0834V16.077C17.0834 16.4979 16.9376 16.8542 16.6459 17.1459C16.3542 17.4376 15.9979 17.5834 15.577 17.5834H4.42321ZM8.09946 13.2788L7.22133 12.4007L14.9553 4.66675H11.6667V3.41675H17.0834V8.83342H15.8334V5.54487L8.09946 13.2788Z" fill="#01a14c"/>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="tab-content">
            <div class="tab-pane" :class="this.activeCameraTab === index ? 'active' :'fade'" :id="tab.id" v-for="(tab, index) in tabsConfig">
                <div class="container">
                    <div class="row" v-if="this.activeCameraTab === index">
                        <WebcamCard :cam="cam" :cacheBreaker="this.cacheBreakerParam" v-for="cam in this.orderCamsByLat(this.$webcams[tab.id])"/>

                        <div class="cam-list-container" v-if="this.getCamOnlyList(this.$webcams[tab.id]).length">
                            <h4 class="cam-list-title">Other webcams</h4>
                            <ul class="cam-list">
                                <li :id="camOnlyLink.alias" class="cam-list-link-item" v-for="camOnlyLink in this.getCamOnlyList(this.$webcams[tab.id])">
                                    <a :href="camOnlyLink.url" target="_blank" class="link">
                                        <country-flag :country="camOnlyLink.country" size='normal'/>
                                        <span class="label">
                                            {{ camOnlyLink.name }} - {{ camOnlyLink.source.label }}
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>    
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>

<script>
import WebcamCard from './webcam-card.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
    name: 'AuroraWebcams',
    components: {
        WebcamCard,
        Swiper,
        SwiperSlide
    },
    data: () => ({
        cacheBreakerParam: '',
        activeCameraTab: 0,
        tabsConfig: [
            {
                id: "sweden",
                label: "Sweden"
            },
            {
                id: "norway",
                label: "Norway"
            },
            {
                id: "finland",
                label: "Finland"
            },
            {
                id: "northEurope",
                label: "North Europe"
            },
            {
                id: "centralEurope",
                label: "Central Europe"
            },
            {
                id: "southEurope",
                label: "South Europe"
            },
            {
                id: "northAmerica",
                label: "North America"
            },
            {
                id: "southAmerica",
                label: "South America"
            },
            {
                id: "russia",
                label: "Russia"
            },
            {
                id: "southAfrica",
                label: "South Africa"
            },
            {
                id: "asiaOceania",
                label: "Asia & Oceania"
            },
        ]
    }),
    mounted () {
        setInterval(() => {
            this.cacheBreakerParam = '?timestamp='+ new Date().getTime();
        }, 10000);

        const urlParams = new URLSearchParams(window.location.search);
        const selectedRegion = urlParams.get('region');

        if (selectedRegion) {
            this.activeCameraTab = this.tabsConfig.findIndex(tab => tab.id === selectedRegion);
            window.history.replaceState({}, document.title, "/");
        }
    },
    methods: {
        orderCamsByLat(cameras, viewAll) {
            if(!cameras) {
                return [];
            }

            return cameras.filter(cam => viewAll ? (!cam.disabled) :  (!cam.disabled && !cam.onlyList)).sort((a, b) => b.coord.lat - a.coord.lat);
        },
        getCamOnlyList(cameras) {
            if (!cameras) {
                return [];
            }

            return cameras.filter(cam => !cam.disabled && cam.onlyList).sort((a, b) => b.coord.lat - a.coord.lat);
        }
    }
}
</script>