<template>
    <div class="page-not-found">
        <img :src="notFoundImgUrl" alt="404 Not found" class="not-found-img">
    </div>
    <button type="button" @click="this.$router.push({path: '/'})" class="backhome-btn">
            Go back to home!
        </button>
  </template>
  
  <script>
  import notFoundImg from '../assets/notfound.svg';

  export default {
    name: 'NotFound',
    data() {
        return {
            notFoundImgUrl: notFoundImg
        }
    }
  };
  </script>