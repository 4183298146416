import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/home.vue'
import WebcamMap from '../views/webcams-map.vue'
import SingleWebcam from '../views/single-webcam.vue';
import Charts from '../views/charts.vue';
import NotFound from '../components/not-found.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {path: '/', name: 'Home', component: Home},
        {path: '/webcams-map', name: 'Webcam Map', component: WebcamMap},
        {path: '/webcam/:region/:alias', name: 'Webcam', component: SingleWebcam},
        {path: '/charts', name: 'Aurora Data Chart', component: Charts},
        {
            path: '/:catchAll(.*)',
            name: 'NotFound',
            component: NotFound,
        },
    ]
})

export default router;