<template>
    <div class="container">
        <div class="row">
            <div class="webcam-header">
                <div class="goback-container">
                    <div class="cta" @click="navigateBack()">
                        <v-icon name="fa-arrow-left" class="icon" scale="1.5" />go back
                    </div>
                </div>
            </div>
        </div>
    </div>

    
    <div class="container" :class="index !== 0 ? 'mt-5' : ''" v-for="(camera, index) in this.webcams">
        <div class="row">
            <div class="webcam-header">
               
                <h4 class="webcam-name"> 
                    <span class="flag-container">
                        <country-flag :country="camera.country" size='normal'/> 
                    </span>
                    <span class="label">
                        {{ camera.name }} <span v-if="camera.isVideo" class="live-cam"> <v-icon name="fa-youtube" class="icon" scale="1.5"/>live cam</span>
                    </span>
                </h4>
            </div>
            <iframe class="external-video" v-if="camera.isVideo && camera.isExternalVideo" width="1175" height="660" :src="camera.url" frameborder="0"></iframe>
            <youtube-iframe v-else-if="camera.isVideo" :video-id="camera.url" width="1280" height="720" frameborder="0" :playerVars="{autoplay: 1, mute: 1}" />
            <img v-else :alt="camera.name" v-lazy="{ src: camera.url+this.cacheBreakerParam, loading: this.loadingImgUrl, error: this.offlineImgUrl }">
        </div>

        <div class="row mt-4">
            <div class="cam-location">
                Location: <a :href="'https://www.google.com/maps/search/?api=1&query='+ camera.coord.lat +','+camera.coord.lng" target="_blank">{{ camera.coord.lat.toFixed(2) }}°N {{ camera.coord.lng.toFixed(2) }}°E</a>
            </div>
            <div class="cam-source" v-if="camera.source.url !== '' && camera.source.label !== ''">
                Source: <a :href="camera.source.url" target="_blank">{{ camera.source.label }}</a>
            </div>
        </div>
    </div>
</template>
  
<script>
import { useRoute } from 'vue-router';
import loadingImg from '../assets/loading.avif';
import offlineImg from '../assets/offline.avif';
import { computed, reactive } from 'vue';
import { useHead } from '@vueuse/head';

export default {
    name: 'AuroraSingleWebcam',
    setup() {
        const route = useRoute();
        const mapAliasTitle = (str) => {
            return str
                .split('-')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        }

        const mapRegionTitle = (str) => {
            str = str.charAt(0).toUpperCase() + str.slice(1);

            let spacedString = str.replace(/([a-z])([A-Z])/g, '$1 $2');
            return spacedString
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(str.toLowerCase() === 'asiaoceania' ? ' & ' : ' ');
        }

        const siteData = reactive({
            title: `${mapAliasTitle(route.params.alias)}, ${mapRegionTitle(route.params.region)} - Webcam - aurorawebcams`,
            description: `${mapAliasTitle(route.params.alias)}, ${mapRegionTitle(route.params.region)} - Webcam - aurorawebcams`,
        })
        useHead({
            title: computed(() => siteData.title),
            meta: [
                {
                name: `description`,
                content: computed(() => siteData.description),
                }
            ],
            link: [
                {
                    rel: 'canonical',
                    href: 'https://aurorawebcams.com' + route.fullPath
                }
            ]
        })
    },
    data() {
      return {
        webcams: null,
        cacheBreakerParam: '',
        loadingImgUrl: loadingImg,
        offlineImgUrl: offlineImg
      }
    },
    created() {
        const route = useRoute();
        this.webcams = this.$webcams[route.params.region].filter(camera => camera.alias === route.params.alias);
    },
    mounted() {
        setInterval(() => {
            this.cacheBreakerParam = '?timestamp='+ new Date().getTime();
        }, 10000);
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from;
        })
    },
    methods: {
        navigateBack() {
            if (!this.prevRoute) {
                this.$router.push({path: '/'});
            }

            if (this.prevRoute.path !== '/' || !this.prevRoute) {
                this.$router.go(-1);
            }

            this.$router.push({path: this.prevRoute.path, query: {region: this.webcams[0].region}});
        },
    }
}
</script>