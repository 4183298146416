<template>
    <div class="container">
        <div class="row">
            <div class="goback-container">
                <div class="cta" @click="this.$router.push({path: '/'});">
                    <v-icon name="fa-arrow-left" class="icon" scale="1.5" />go back
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <h4 class="mb-5">Interplanetary Magnetic Field</h4>
        <div class="row justify-content-center" v-if="!store.auroraData.magData.length && !store.auroraData.windData.length">
            <div class="loader"></div>
        </div>
        <Responsive class="w-full" v-if="store.auroraData.magData.length && store.auroraData.windData.length">
            <template #main="{width}">
                <Chart
                    class="magChart"
                    :size="{width, height: 400}"
                    :data="this.filterChartData(store.auroraData.magData, this.chartMagInterval)"
                    :axis="this.magAxis">
                
                    <template #layers>
                        <Marker :value="0" color="#01a14c" :strokeWidth="2" strokeDasharray="1,0" :strokeOpacity="1" />
                        <g v-if="store.magEarthLineTransform" class="earth layer-marker" :transform="store.magEarthLineTransform">
                            <text :x="10" :y="5" text-anchor="start" :fill="'orange'" style="writing-mode: tb;">{{ 'Earth' }}</text>
                            <line
                            :x1="0"
                            :y1="0"
                            :x2="0"
                            :y2="800"
                            :stroke="'orange'"
                            :stroke-opacity="1"
                            :stroke-width="1"
                            :stroke-dasharray="'1,0'"
                            />
                            <text class="date" :x="10" :y="380" text-anchor="end" :fill="'orange'" style="writing-mode: tb;">{{ store.magEarthLineTime }}</text>
                        </g>
                        <Grid strokeDasharray="1,1" stroke="white" stroke-width="0.5" :hide-y="true"/>
                        <Line class="red" v-if="showBz" :dataKeys="['time_tag', 'bz_gsm']" type="natural" :lineStyle="{ stroke: '#a00101', strokeWidth: 1 }" :hideDot="true"/>
                        <Line class="white" v-if="showBt" :dataKeys="['time_tag', 'bt']" type="natural" :lineStyle="{ stroke: 'white', strokeWidth: 1 }" :hideDot="true" />
                        <Line class="purple" v-if="showBy" :dataKeys="['time_tag', 'by_gsm']" type="natural" :lineStyle="{ stroke: 'purple', strokeWidth: 1 }" :hideDot="true"/>
                        <Line class="cyan" v-if="showBx" :dataKeys="['time_tag', 'bx_gsm']" type="natural" :lineStyle="{ stroke: '#007ba8', strokeWidth: 1 }" :hideDot="true"/>
                    </template>
                </Chart>
            </template>
        </Responsive>
    </div>

    <div class="container mt-3">
        <sub class="info-chart">Real time magnetic field data are displayed. <br>You can choose which data display on chart and its range below.</sub>
        <div class="row mt-3">
            <div class="col-12 col-sm-9">
                <div class="row m-0">
                    <template v-for="btn in linesButtonConfig">
                        <div :class="btn.containerClass">
                            <button type="button" :aria-label="btn.ariaLabel" class="chart-button" :class="{'disabled': !btn.active}" :id="btn.id" @click="toggleData(btn)">
                                {{ btn.label }}
                            </button>
                        </div>
                    </template>
                </div>
            </div>

            <div class="col-12 col-sm-3">
                <select id="magIntervalSelect" class="form-select chart-interval-select mt-4 mt-sm-0" aria-label="Change interval" v-model="this.chartMagInterval" @change="store.setEarthLineMarker()">
                    <option selected disabled>Set chart interval here</option>
                    <option value="24">Chart interval: 1h</option>
                    <option value="12">Chart interval: 2h</option>
                    <option value="6">Chart interval: 4h</option>
                    <option value="4">Chart interval: 6h</option>
                    <option value="2">Chart interval: 12h</option>
                    <option value="1">Chart interval: 24h</option>
                </select>
            </div>
            
        </div>
        
        <hr class="mt-5">
    </div>

    <div class="container mt-5">
        <h4 class="mb-5">Solar Wind Density: {{ store.auroraData.windData && store.auroraData.windData[0] ? store.auroraData.windData[0].proton_density : '-' }} p/cm<sup>3</sup></h4>
        <div class="row justify-content-center" v-if="!store.auroraData.windData.length">
            <div class="loader"></div>
        </div>
        <Responsive class="w-full" v-if="store.auroraData.windData.length">
            <template #main="{width}">
                <Chart
                    class="denChart"
                    :size="{width, height: 400}"
                    :data="this.filterChartData(store.auroraData.windData, this.chartDensityInterval)"
                    :axis="this.windDensityAxis">
                
                    <template #layers>
                        <Grid strokeDasharray="1,1" stroke="white" stroke-width="0.5" :hide-y="true"/>
                        <Area :dataKeys="['time_tag', 'proton_density']" type="natural" :areaStyle="{ fill: 'url(#grad)', fillOpacity: 1 }"/>
                    
                        <g v-if="store.denEarthLineTransform" class="earth layer-marker" :transform="store.denEarthLineTransform">
                            <text :x="10" :y="5" text-anchor="start" :fill="'orange'" style="writing-mode: tb;">{{ 'Earth' }}</text>
                            <line
                            :x1="0"
                            :y1="0"
                            :x2="0"
                            :y2="800"
                            :stroke="'orange'"
                            :stroke-opacity="1"
                            :stroke-width="1"
                            :stroke-dasharray="'1,0'"
                            />
                            <text class="date" :x="10" :y="380" text-anchor="end" :fill="'orange'" style="writing-mode: tb;">{{ store.denEarthLineTime }}</text>
                        </g>
                        <defs>
                            <linearGradient id="grad" gradientTransform="rotate(90)">
                            <stop offset="0%" stop-color="#01a14c" stop-opacity="1" />
                            <stop offset="100%" stop-color="#01a14c" stop-opacity="0.4" />
                            </linearGradient>
                        </defs>             
                    </template>
                </Chart>
            </template>
        </Responsive>
    </div>

    <div class="container mt-3">
        <sub class="info-chart">Solar wind density data are displayed. You can choose data display range below.</sub>
        <div class="row mt-3 justify-content-end">
            <div class="col-12 col-sm-3">
                <select id="denIntervalSelect" class="form-select chart-interval-select" aria-label="Change interval" v-model="this.chartDensityInterval" @change="store.setEarthLineMarker()">
                    <option selected disabled>Set chart interval here</option>
                    <option value="24">Chart interval: 1h</option>
                    <option value="12">Chart interval: 2h</option>
                    <option value="6">Chart interval: 4h</option>
                    <option value="4">Chart interval: 6h</option>
                    <option value="2">Chart interval: 12h</option>
                    <option value="1">Chart interval: 24h</option>
                </select>
            </div>
            
        </div>

        <hr class="mt-5">
    </div>

    <div class="container mt-5">
        <h4 class="mb-5">Solar Wind Speed: {{ store.auroraData.windData && store.auroraData.windData[0] ? store.auroraData.windData[0].proton_speed : '-'}} km/s</h4>
        <div class="row justify-content-center" v-if="!store.auroraData.windData.length">
            <div class="loader"></div>
        </div>
        <Responsive class="w-full" v-if="store.auroraData.windData.length">
            <template #main="{width}">
                <Chart
                    class="speedChart"
                    :size="{width, height: 400}"
                    :data="this.filterChartData(store.auroraData.windData, this.chartSpeedInterval)"
                    :axis="this.windSpeedAxis">
                
                    <template #layers>
                        <Grid strokeDasharray="1,1" stroke="white" stroke-width="0.5" :hide-y="true"/>
                        <Area :dataKeys="['time_tag', 'proton_speed']" type="natural" :areaStyle="{ fill: 'url(#grad)', fillOpacity: 1 }"/>

                        <g v-if="store.speedEarthLineTransform" class="earth layer-marker" :transform="store.speedEarthLineTransform">
                            <text :x="10" :y="5" text-anchor="start" :fill="'orange'" style="writing-mode: tb;">{{ 'Earth' }}</text>
                            <line
                            :x1="0"
                            :y1="0"
                            :x2="0"
                            :y2="800"
                            :stroke="'orange'"
                            :stroke-opacity="1"
                            :stroke-width="1"
                            :stroke-dasharray="'1,0'"
                            />
                            <text class="date" :x="10" :y="380" text-anchor="end" :fill="'orange'" style="writing-mode: tb;">{{ store.speedEarthLineTime }}</text>
                        </g>
                        <defs>
                            <linearGradient id="grad" gradientTransform="rotate(90)">
                            <stop offset="0%" stop-color="#01a14c" stop-opacity="1" />
                            <stop offset="100%" stop-color="#01a14c" stop-opacity="0.4" />
                            </linearGradient>
                        </defs> 
                    </template>
                </Chart>
            </template>
        </Responsive>
    </div>

    <div class="container mt-3">
        <sub class="info-chart">Solar wind speed data are displayed. You can choose data display range below.</sub>
        <div class="row mt-3 justify-content-end">
            <div class="col-12 col-sm-3">
                <select id="speedIntervalSelect" class="form-select chart-interval-select" aria-label="Change interval" v-model="this.chartSpeedInterval" @change="store.setEarthLineMarker()">
                    <option selected disabled>Set chart interval here</option>
                    <option value="24">Chart interval: 1h</option>
                    <option value="12">Chart interval: 2h</option>
                    <option value="6">Chart interval: 4h</option>
                    <option value="4">Chart interval: 6h</option>
                    <option value="2">Chart interval: 12h</option>
                    <option value="1">Chart interval: 24h</option>
                </select>
            </div>
            
        </div>

        <hr class="mt-5">
    </div>

    <div class="container mt-5">
        <h4 class="mb-5">Hemispheric Power</h4>
        <div class="row justify-content-center" v-if="!store.hemiPowerData.length">
            <div class="loader"></div>
        </div>
        <Responsive class="w-full" v-if="store.hemiPowerData && store.hemiPowerData.length">
            <template #main="{width}">
                <Chart
                    class="hemiPowerChart"
                    :size="{width, height: 400}"
                    :data="store.hemiPowerData"
                    :axis="this.hemiPowerAxis">
                
                    <template #layers>
                        <Grid strokeDasharray="1,1" stroke="white" stroke-width="0.5" :hide-y="true"/>
                        <Marker :value="0" color="#01a14c" :strokeWidth="2" strokeDasharray="1,0" :strokeOpacity="1" />
                        <Bar :dataKeys="['observation', 'northHemi']" :barStyle="northHemiBarStyle" />
                        <Bar :dataKeys="['observation', 'southHemi']" :barStyle="southHemiBarStyle" />

                        <g v-if="store.hemiLineTransform" class="earth layer-marker" :transform="store.hemiLineTransform">
                            <text :x="10" :y="5" text-anchor="start" :fill="'orange'" style="writing-mode: tb;">{{ 'Earth' }}</text>
                            <line
                            :x1="0"
                            :y1="0"
                            :x2="0"
                            :y2="800"
                            :stroke="'orange'"
                            :stroke-opacity="1"
                            :stroke-width="1"
                            :stroke-dasharray="'1,0'"
                            />
                            <text class="date" :x="10" :y="380" text-anchor="end" :fill="'orange'" style="writing-mode: tb;">{{ store.hemiEarthLineTime }}</text>
                        </g>
                    </template>
                </Chart>
            </template>
        </Responsive>
    </div>

    <div class="container mt-3" v-if="store.hemiPowerData && store.hemiPowerData.length">
        <sub class="info-chart mb-3">The OVATION model calculates a globally integrated total energy deposition in GigaWatts for up to 30 minutes in the future. For values below about 20GW, there may be little or no aurora observable. For values between 20 and 50, you may need to be near the aurora to see it. For values above 50, the aurora should be quite observable with lots of activity and motion across the sky. Once the Hemispheric Power reaches levels of 100 or more, this is considered to be a very significant geomagnetic storm.</sub>
        <sub class="info-chart mb-1">Northern hemisphere: 
            <span class="hemi-power-info"
                :class="{'green': store.hemiPowerData[store.hemiPowerData.length - 1].northHemi < 20, 'yellow': store.hemiPowerData[store.hemiPowerData.length - 1].northHemi >= 20 && store.hemiPowerData[store.hemiPowerData.length - 1].northHemi <= 50, 'red': store.hemiPowerData[store.hemiPowerData.length - 1].northHemi > 50}">
                {{store.hemiPowerData[store.hemiPowerData.length - 1].northHemi }} GW 
            </span>
        </sub>
        <sub class="info-chart">Southern hemisphere: 
            <span class="hemi-power-info"
            :class="{'green': -store.hemiPowerData[store.hemiPowerData.length - 1].southHemi < 20, 'yellow': -store.hemiPowerData[store.hemiPowerData.length - 1].southHemi >= 20 && -store.hemiPowerData[store.hemiPowerData.length - 1].southHemi <= 50, 'red': -store.hemiPowerData[store.hemiPowerData.length - 1].southHemi > 50}">   
                {{-store.hemiPowerData[store.hemiPowerData.length - 1].southHemi }} GW
            </span>
        </sub>

        <hr class="mt-5">
    </div>
  </template>
  
<script>
import { Chart, Grid, Line, Responsive, Area, Marker, Bar } from 'vue3-charts';
import { store } from '../js/store.js';
import { computed, reactive, ref } from 'vue';
import { useHead } from '@vueuse/head';
import { useRoute } from 'vue-router';
  
  export default {
    name: 'LineChart',
    components: { 
        Chart,
        Grid,
        Line,
        Responsive,
        Area,
        Marker,
        Bar
    },
    setup() {
        const route = useRoute();
        const siteData = reactive({
            title: `Real-Time Activity Charts - aurorawebcams`,
            description: `Real-Time Activity Charts - aurorawebcams`,
        })
        useHead({
            title: computed(() => siteData.title),
            meta: [
                {
                name: `description`,
                content: computed(() => siteData.description),
                }
            ],
            link: [
                {
                    rel: 'canonical',
                    href: 'https://aurorawebcams.com' + route.fullPath
                }
            ]
        })
    },
    data: () => ({
        store: store,
        showBz: true,
        showBt: true,
        showBx: false,
        showBy: false,
        chartMagInterval: 4,
        chartDensityInterval: 4,
        chartSpeedInterval: 4,
        linesButtonConfig: [
            {
                id: 'bt',
                label: 'Bt',
                active: true,
                ariaLabel: 'Toggle Bt',
                containerClass: 'col-3 col-lg-2'
            },
            {
                id: 'bz',
                label: 'Bz',
                active: true,
                ariaLabel: 'Toggle Bz',
                containerClass: 'col-3 col-lg-2'
            },
            {
                id: 'bx',
                label: 'Bx',
                active: false,
                ariaLabel: 'Toggle Bx',
                containerClass: 'col-3 col-lg-2'
            },
            {
                id: 'by',
                label: 'By',
                active: false,
                ariaLabel: 'Toggle By',
                containerClass: 'col-3 col-lg-2'
            }
        ],    
        magAxis: {
            secondary: {
                domain: ['dataMin - 2', 'dataMax + 2'],
                type: 'linear',
                ticks: 10
            }
        },
        windDensityAxis: {
            secondary: {
                domain: ['0', 'dataMax + 2'],
                type: 'linear',
                ticks: 10
            }
        },
        windSpeedAxis: {
            secondary: {
                domain: ['0', 'dataMax + 100'],
                type: 'linear',
                ticks: 10
            }
        },
        hemiPowerAxis: {
            secondary: {
                domain: ['dataMin - 10', 'dataMax + 10'],
                type: 'linear',
                ticks: 10
            }
        }
    }),
    methods: {
        toggleData(btn) {
            const selector = 'show'+btn.label;
            this[selector] = !this[selector];
            btn.active = !btn.active;

            const noActiveElements = this.linesButtonConfig.every(el => !el.active);
            if (noActiveElements) {
                btn.active = true;
                this[selector] = true;
            }
        },
        filterChartData(data, interval) {
            const half_length = Math.ceil(data.length / interval);
            return data.slice(0, half_length).reverse();
        },
        northHemiBarStyle(dataPoint){
            return {fill: this.computeColor(dataPoint.data.northHemi)}
        },
        southHemiBarStyle(dataPoint){
            return {fill: this.computeColor((-dataPoint.data.southHemi))}
        },
        computeColor(value) {
            if (value < 20) {
                return 'green';
            } else if (value >= 20 && value < 50) {
                return 'yellow';
            } else if (value >= 50) {
                return '#a00101'
            }
        },
    }
  }
  </script>