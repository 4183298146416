<template>
    <AppHeader />
    <router-view></router-view>
    <button type="button" id="scroll-to-top-btn" class="scroll-top-btn" aria-label="scroll-top-btn" @click="scrollToTop()">
      <v-icon name="fa-arrow-circle-up" class="icon" scale="1.5"/>
    </button>
</template>

<script>
import { computed, reactive } from 'vue';
import AppHeader from './components/app-header.vue';
import { store } from './js/store.js';
import { useHead } from '@vueuse/head';
import { useRoute } from 'vue-router';

export default {
  data() {
    return {
    }
  },
  components: {
    AppHeader
  }, 
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    }
  },
  setup() {
    const route = useRoute();
    const siteData = reactive({
      title: `aurorawebcams - A free all-in-one application for Aurora hunters`,
      description: `A free all-in-one application for Aurora hunters: that is all you need to chase Northern Lights with a lot of webcams to monitor real-time auroral activity`,
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
      ],
      link: [
        {
            rel: 'canonical',
            href: 'https://aurorawebcams.com' + route.fullPath
        }
      ]
    })
  },
  created() {
    store.getData().then(() => setTimeout(() => store.setEarthLineMarker()));
  },
  mounted() {
    window.addEventListener('scroll', function() {
      const scrollToTopBtn = document.getElementById('scroll-to-top-btn');

      if (scrollToTopBtn) {
        let windowPageYOffset = window.pageYOffset || document.documentElement.scrollTop;
        if (windowPageYOffset > 300) {
          scrollToTopBtn.classList.add('show');
        } else {
          scrollToTopBtn.classList.remove('show');
        }
      }
    });

    document.querySelector('body').classList.add('loaded');

    store.fetchData();
  }
}
</script>