<template>
    <div class="container data-container">
      <h4>Real Time Auroral Activity Data</h4>

      <div class="loader" v-if="!store.auroraData.magData.length && !store.auroraData.windData.length"></div>

      <div class="aurora-data" v-if="store.auroraData.magData.length && store.auroraData.windData.length">
        <div class="aurora-data-item">Current Bt: <strong>{{ store.auroraData.magData[0].bt }} nT </strong></div>
        <div class="aurora-data-item">Current Bz: <strong>{{ store.auroraData.magData[0].bz_gsm }} nT </strong><span class="bz-direction" :class="store.auroraData.magData[0].bz_gsm > 0 ? 'green' : 'red'">{{ store.auroraData.magData[0].bz_gsm > 0 ? 'North' : 'South' }} </span></div> 
        <div class="aurora-data-item">Solar wind speed: <strong>{{ store.auroraData.windData[0].proton_speed }} km/s</strong></div> 
        <div class="aurora-data-item">Solar wind density: <strong>{{ store.auroraData.windData[0].proton_density }} p/cm<sup>3</sup></strong></div> 
        <div class="aurora-data-item">Source: <strong>{{ store.auroraData.magData[0].source }}</strong></div> 
      </div>

      <div class="wind-speed-propagation-box" v-if="store.windSpeedPropagationMinutes && this.hideChartLink">
          With the current speed, it will take the solar wind <strong>{{ store.windSpeedPropagationMinutes }} minutes</strong> to propagate from DSCOVR to Earth.
      </div>

      <div class="update-timetag mt-2 mb-2" v-if="store.auroraData.magData.length">
        <code>Updated at: {{ formatTimeTag(store.auroraData.magData[0].time_tag) }}</code>
      </div>

      <a v-if="!this.hideChartLink" class="chart-link d-block" :href="'/charts'">go to Aurora Activity Data Charts</a>
      <hr class="w-100">
    </div>
</template>



<script>
import { store } from '../js/store.js';

export default {
  name: 'AuroralActivityData',
  props: [
    'isSticky'
  ],
  data: () => ({
    hideChartLink: false,
    store: store
  }),
  watch: {
    $route: function() {
      this.hideChartLink = this.$route.path === "/charts";
    }
  },
  methods: {
    formatTimeTag(dateString) {
      const timezoneOffset = new Date().getTimezoneOffset();
      const timetag = new Date(dateString);
      const timetagTimezoned = new Date (timetag.getTime() - timezoneOffset * 60000);

      return timetagTimezoned.toLocaleString();
    }
  }
}
</script>