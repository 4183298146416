<template>
  <header class="header">
    <h1 class="title"><a :href="'/'">aurora<span class="light-weight">webcams</span></a></h1>
    <h6 class="m-0">A free all-in-one application for Aurora hunters</h6>
    <sub>powered by <a href="https://www.instagram.com/mauro.privitera/" target="_blank">Mauro Privitera</a> & <a href="https://www.instagram.com/arcticroadtrips/" target="_blank">Arctic Road Trips</a></sub>
    <hr>
    <AuroralActivityData />
  </header>

  <header class="header sticky" v-if="this.isSticky">
    <h1 class="title"><a :href="'/'">aurora<span class="light-weight">webcams</span></a></h1>
    <hr>
    <div class="container data-container" v-if="store.auroraData.magData.length">
      <div class="aurora-data">
        <div class="aurora-data-item">Current Bt: <strong>{{ store.auroraData.magData[0].bt }} nT</strong></div>
        <div class="aurora-data-item">Current Bz: <strong>{{ store.auroraData.magData[0].bz_gsm }} nT</strong> <span class="bz-direction" :class="store.auroraData.magData[0].bz_gsm > 0 ? 'green' : 'red'">{{ store.auroraData.magData[0].bz_gsm > 0 ? 'North' : 'South' }} </span></div> 
      </div>
    </div>
  </header>

</template>
  
<script>
import AuroralActivityData from './auroral-activity-data.vue';
import { store } from '../js/store.js';

  export default {
    name: 'AuroraWebcamHeader',
    components: {
      AuroralActivityData
    },
    data: () => ({
      isSticky: false,
      store: store
    }),
    created() {
      window.addEventListener('scroll', () => {
        this.isSticky = window.scrollY > 200;
      });
    }
  }
</script>