import { reactive } from 'vue';

export const store = reactive({
  urls: {
      rtsw_mag: 'https://services.swpc.noaa.gov/json/rtsw/rtsw_mag_1m.json',
      rtsw_wind: 'https://services.swpc.noaa.gov/json/rtsw/rtsw_wind_1m.json',
      hemi_power: 'https://services.swpc.noaa.gov/text/aurora-nowcast-hemi-power.txt'
  },
  auroraData: {
      magData: [],
      windData: []
  },
  hemiPowerData: [],
  magEarthLineTransform: null,
  denEarthLineTransform: null,
  speedEarthLineTransform: null,
  hemiLineTransform: null,
  windSpeedPropagationMinutes: null,
  async getData() {
      try {
          let ACEmagData;
          let DSCVRmagData;
          let ACEwindData;
          let DSCVRwindData;
  
          const magResponse = await fetch(this.urls.rtsw_mag);
          const magResponseObj = await magResponse.json();
  
          if (magResponseObj) {
            ACEmagData = magResponseObj.filter(data => data.active && data.source === 'ACE');
            DSCVRmagData = magResponseObj.filter(data => data.active && data.source === 'DSCOVR');
          }
  
          const windResponse = await fetch(this.urls.rtsw_wind);
          const windResponseObj = await windResponse.json();
        
          if (windResponseObj) {
            ACEwindData = windResponseObj.filter(data => data.active && data.source === 'ACE' && data.proton_speed && data.proton_density);
            DSCVRwindData = windResponseObj.filter(data => data.active && data.source === 'DSCOVR' && data.proton_speed && data.proton_density);
          }
  
          if (ACEmagData.length && ACEwindData.length) {
            this.auroraData.magData = ACEmagData;
            this.auroraData.windData = ACEwindData;
          }
  
          if (DSCVRmagData.length && DSCVRwindData.length) {
            this.auroraData.magData = DSCVRmagData;
            this.auroraData.windData = DSCVRwindData;
          }

        await this.fetchHemiPowerFile();
        } catch (e) {
          console.error(e)
        }
  },
  fetchData() {
      setTimeout(async()=> {
        await this.getData();
        await this.setEarthLineMarker();
      }, 60000)
  },
  setEarthLineMarker() {
    this.magEarthLineTransform = null;
    this.denEarthLineTransform = null;
    this.speedEarthLineTransform = null;
    this.hemiLineTransform = null;
    this.magEarthLineTime = null;
    this.denEarthLineTime = null;
    this.speedEarthLineTime = null;
    this.hemiLineTime = null;
    this.windSpeedPropagationMinutes = Math.round((1500234 / this.auroraData.windData[0].proton_speed) / 60);
    const intervalMs = this.windSpeedPropagationMinutes * 60000;
    const nowMs = Date.now();
    const earthImpactDate = new Date(nowMs - intervalMs).toISOString().slice(0, -5);

    const axisMag = document.querySelector('.magChart .layer-axis-x');
    const axisDen = document.querySelector('.denChart .layer-axis-x');
    const axisSpeed = document.querySelector('.speedChart .layer-axis-x');
    const axisHemi = document.querySelector('.hemiPowerChart .layer-axis-x');
    const magIntervalSelect = document.getElementById('magIntervalSelect');
    const denIntervalSelect = document.getElementById('denIntervalSelect');
    const speedIntervalSelect = document.getElementById('speedIntervalSelect');

    if (axisMag) {
      if (magIntervalSelect && this.windSpeedPropagationMinutes <= ((24 / magIntervalSelect.value) * 60)){
        console.warn('Interplanetary Magnetic Field Chart: Earth Line Marker is in the chart interval');
        const ticks = axisMag.querySelectorAll('.tick');
        const currentEarthTick = this.findClosestDate(earthImpactDate.slice(0, -3), Array.from(ticks));
        if (currentEarthTick) {
          this.magEarthLineTransform = currentEarthTick.getAttribute('transform');
          this.magEarthLineTime = this.formatDate(new Date(currentEarthTick.textContent.trim()));
        }
      } else {
        console.warn('Interplanetary Magnetic Field Chart: Earth Line Marker is NOT in the chart interval');
        this.magEarthLineTransform = null;
      }  
    }

    if (axisDen) {
      if (denIntervalSelect && this.windSpeedPropagationMinutes <= ((24 / denIntervalSelect.value) * 60)){
        console.warn('Solar Wind Density Chart: Earth Line Marker is in the chart interval');
        const ticks = axisDen.querySelectorAll('.tick');
        const currentEarthTick = this.findClosestDate(earthImpactDate.slice(0, -3), Array.from(ticks));
        if (currentEarthTick) {
          this.denEarthLineTransform = currentEarthTick.getAttribute('transform');
          this.denEarthLineTime = this.formatDate(new Date(currentEarthTick.textContent.trim()));
        }
      } else {
        console.warn('Solar Wind Density Chart: Earth Line Marker is NOT in the chart interval');
        this.denEarthLineTransform = null;
      }
    }

    if (axisSpeed) {
      if (speedIntervalSelect && this.windSpeedPropagationMinutes <= ((24 / speedIntervalSelect.value) * 60)){
        console.warn('Solar Wind Speed Chart: Earth Line Marker is in the chart interval');
        const ticks = axisSpeed.querySelectorAll('.tick');
        const currentEarthTick = this.findClosestDate(earthImpactDate.slice(0, -3), Array.from(ticks));
        if (currentEarthTick) {
          this.speedEarthLineTransform = currentEarthTick.getAttribute('transform');
          this.speedEarthLineTime = this.formatDate(new Date(currentEarthTick.textContent.trim()));
        }
      } else {
        console.warn('Solar Wind Speed Chart: Earth Line Marker is NOT in the chart interval');
        this.speedEarthLineTransform = null;
      }
    }
    
    if (axisHemi) {
      console.warn('Hemisperich Power: Earth Line Marker is in the chart interval');
      const ticks = axisHemi.querySelectorAll('.tick');
      const currentEarthTick = this.findClosestDate(earthImpactDate.slice(0, -3), Array.from(ticks));
      if (currentEarthTick) {
        this.hemiLineTransform = currentEarthTick.getAttribute('transform');
        const hemiDatestring = currentEarthTick.textContent.trim().replace('_', 'T');
        this.hemiEarthLineTime = this.formatDate(new Date(hemiDatestring));
      }
    }
  },
  async fetchHemiPowerFile() {
    const url = this.urls.hemi_power;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const text = await response.text();
      this.hemiPowerData = this.parseHemiPowerTextToJSON(text);
    } catch (error) {
      console.error('Error fetching or parsing the file:', error);
    }
  },
  parseHemiPowerTextToJSON(text) {
    const lines = text.split('\n');
    const data = [];
    
    lines.forEach(line => {
      if (line && !line.startsWith('#')) {
        const parts = line.split(/\s+/);
        if (parts.length === 4) {
          const [observation, forecast, northHemi, southHemi] = parts;
          data.push({ observation: observation, forecast: forecast, northHemi: parseInt(northHemi), southHemi: parseInt(-southHemi) });
        }
      }
    });
      
    return data;
  },
  formatDate(inputTimeStr) {
    // Creare un oggetto Data dalla stringa
    const dateUTC = new Date(inputTimeStr);

    const timezoneOffset = new Date().getTimezoneOffset();
    const date = new Date (dateUTC.getTime() - timezoneOffset * 60000);

    // Ottenere i componenti della data e dell'ora
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // I mesi sono indicizzati a partire da 0
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Formattare la data e l'ora nel formato desiderato
    return `${day}/${month} ${hours}:${minutes}`;
  },
  extractTextFromElements(elements) {
    return Array.from(elements).map(el => el.textContent.trim());
  },
  findClosestDate(referenceDateStr, elements) {
    const dateTexts = this.extractTextFromElements(elements);
    const referenceDate = new Date(referenceDateStr);
    
    let closestDate = null;
    let closestDiff = Infinity;
  
    dateTexts.forEach(dateStr => {
      let string = dateStr;
      if (dateStr.includes('_')) {
        string = dateStr.replace('_', 'T');
      }

      const currentDate = new Date(string);
      const diff = Math.abs(referenceDate - currentDate);
  
      if (diff < closestDiff) {
        closestDiff = diff;
        closestDate = string;
      }
    });
  
    return elements.filter(el => {
      let string = el.textContent.trim();
      if (string.includes('_')) {
        string = string.replace('_', 'T');
      }
      return string === closestDate})[0];
  }
});