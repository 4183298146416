<template>
    <div class="container">
        <h4 class="mb-3">Webcams map</h4>

        <div class="row">
            <div class="goback-container">
                <div class="cta" @click="this.$router.push({path: '/'});">
                    <v-icon name="fa-arrow-left" class="icon" scale="1.5" />go back
                </div>
            </div>
        </div>
        <div class="row">
            <GMapMap
                :center="mapCenter"
                :zoom="4"
                map-type-id="hybrid"
                style="max-width: 100%; height: 600px; width: 90vw"
                :options="{
                            zoomControl: true,
                            mapTypeControl: false,
                            scaleControl: true,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                }" 
            >
                <GMapCluster
                    :minimumClusterSize="this.minimumClusterSize" >
                    <GMapMarker
                        :key="index"
                        v-for="m in mapMarkers"
                        :position="m.position"
                        :icon="cameraIconObj"
                        :clickable="true"
                        :draggable="false"
                        @click="showWebcam(m)"
                    />
                </GMapCluster>
            </GMapMap>
        </div>
    </div>
</template>
  
<script>
  import { computed, reactive } from 'vue';
import cameraIcon from '../assets/icons/camera.svg';
import { useHead } from '@vueuse/head';
import { useRoute } from 'vue-router';

  export default {
    name: 'AuroraWebcamMap',
    setup() {
        const route = useRoute();
        const siteData = reactive({
            title: `Webcams map - aurorawebcams`,
            description: `Webcams map - aurorawebcams`,
        })
        useHead({
            title: computed(() => siteData.title),
            meta: [
                {
                name: `description`,
                content: computed(() => siteData.description),
                }
            ],
            link: [
                {
                    rel: 'canonical',
                    href: 'https://aurorawebcams.com' + route.fullPath
                }
            ]
        })
    },
    data() {
      return {
        mapCenter: {lat: 62.75255446054112, lng: 17.732204549548836},
        mapMarkers: [
        ],
        cameraIconObj: {
            url: cameraIcon,
            scaledSize: {width: 32, height: 32},
            labelOrigin: {x: 16, y: -10}
        },
        selectedWebcam: null,
        minimumClusterSize: 1
      }
    },
    mounted() {
        this.mapMarkers = [];

        Object.keys(this.$webcams).forEach(region => {
            if (this.$webcams[region]) {
                this.$webcams[region].forEach((camera) => {
                    const marker = {
                        id: this.mapMarkers.length,
                        position: {
                            lat: camera.coord.lat,
                            lng: camera.coord.lng
                        },
                        alias: camera.alias,
                        region: camera.region
                    }

                    if (camera.onlyList) {
                        marker.url = camera.url
                    }

                    const markerPositionString = marker.position.lat + marker.position.lng;
                    const alreadyExist = this.mapMarkers.find(m => (m.position.lat + m.position.lng) === markerPositionString);

                    if (!alreadyExist) {
                        this.mapMarkers.push(marker);
                    }

                    this.minimumClusterSize++
                });
            }
        })
    },
    methods: {
        showWebcam(marker) {
            if (marker.url) {
                window.open(marker.url, '_blank');
            } else {
                this.$router.push({name: 'Webcam', params: {alias: marker.alias, region: marker.region}});
            }
        }
    }
  }
</script>